const moreArticles = [
    {
      uid: 1,
      title: "Air pollution and depression",
      subtitle: "Environment And Mental Health",
      articleDesc: `
      Air pollution, a prevalent environmental issue in many parts of the world, has been linked to a wide range of adverse health effects. While its...
          `,
      articlepagehref: `/AirPollutionAndDepression`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Air pollution and depression triggers Image`,
    },
    {
        uid: 2,
        title: "Air pollution and mental health",
        subtitle: "Environment And Mental Health",
        articleDesc: `
        Air pollution is a significant environmental issue that has been linked to a range of physical health problems, including respiratory and cardiovascular diseases...
            `,
        articlepagehref: `/AirPollutionAndMentalHealth`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Air pollution and mental health Image`,
      },
      {
        uid: 3,
        title: "Chemical pollution and mental health",
        subtitle: "Environment And Mental Health",
        articleDesc: `
        Diet plays a vital role in our physical health, but emerging research suggests that it may also have a significant impact on our mental well-being... 
            `,
        articlepagehref: `/ChemicalPollutionAndMentalHealth`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Chemical pollution and mental health Image`,
      },
      {
        uid: 4,
        title: "Housing And Mental Health",
        subtitle: "Environment And Mental Health",
        articleDesc: `
        The importance of having access to adequate housing cannot be overstated. Housing provides us with shelter, safety, and security, and is essential...  
            `,
        articlepagehref: `/HousingAndMentalHealth`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Housing And Mental Health Image`,
      }, 
      {
        uid: 5,
        title: "Water pollution and mental health",
        subtitle: "Environment And Mental Health",
        articleDesc: `
        Water is one of the most essential resources for human survival. However, with the increase in industrialization and human activity, water pollution...  
            `,
        articlepagehref: `/WaterPollutionAndMentalHealth`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Water pollution and mental health Image`,
      }
]

export { moreArticles };