import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './latestArticles.css'
function LatestArticles() {
  return (
      <Row xs={1} md={2}>
        <Col>
        <div className='articleSpacing'>
            <Card style={{ width: '100%' }}>
                <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the
                            bulk of the card's content.
                        </Card.Text>
                    </Card.Body>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>Cras justo odio</ListGroup.Item>
                            <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                            <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                        </ListGroup>
                        <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href="/ArticleOne" className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
        </div>
        </Col>
        <Col>
        <div className='articleSpacing'>
        <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the
                        bulk of the card's content.
                    </Card.Text>
                </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                    <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href="/ArticleOne" className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
            </div>
        </Col>
        <Col>
        <div className='articleSpacing'>
        <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
                <Card.Body>
                    <Card.Title>Card Title</Card.Title>
                    <Card.Text>
                        Some quick example text to build on the card title and make up the
                        bulk of the card's content.
                    </Card.Text>
                </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>Cras justo odio</ListGroup.Item>
                        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                    </ListGroup>
                    <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href="/ArticleOne" className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
            </div>
        </Col>
      </Row>
  );
}

export default LatestArticles;