const moreArticles = [
  {
    uid: 1,
    title: "Reassurance",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FDrops%20glass%20in%20excitement%20First%20person%20logged%20in%20and%20watched%20a%20seminar.jpeg?alt=media&token=35c57140-ea36-428f-99fb-2d00253fae86
    `, 
    date: 'August 2nd 2023',
    articleDesc: `
    I haven’t allowed myself the proper time to draw back the curtains and to peer into the person I am....`,
    articlepagehref: `/Reassurance`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 2,
    title: "I was wrong",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FDrops%20glass%20in%20excitement%20First%20person%20logged%20in%20and%20watched%20a%20seminar.jpeg?alt=media&token=35c57140-ea36-428f-99fb-2d00253fae86
    `, 
    date: 'July 24th 2023',
    articleDesc: `
    I have come to realize that I have been wrong about many things - from what I enjoy to how I find clarity and motivation. Recently, my run to Brighton allowed me to touch a place I had been unable to explore while living in the environment I had crafted for myself...`,
    articlepagehref: `/IWasWrong`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 3,
    title: "Drops glass in excitement: First person logged in and watched a seminar",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FDrops%20glass%20in%20excitement%20First%20person%20logged%20in%20and%20watched%20a%20seminar.jpeg?alt=media&token=35c57140-ea36-428f-99fb-2d00253fae86
    `, 
    date: 'July 17th 2023',
    articleDesc: `
    ...Anyway - what I wanted to tell you is they have logged in and as of 19:20 (Monday July 17th), we have had our first student watch a seminar. Guess which one it was Nicole, of-course, the History of the African diaspora. I nearly cried seeing it....`,
    articlepagehref: `/WeHaveHadOurFirstPersonLogin`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 4,
    title: "A Fond Memory",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FselfCompassion.jpeg?alt=media&token=ec512bb6-ecbf-4a28-8ef4-f7dfe9747e7c
    `, 
    date: 'July 10th 2023',
    articleDesc: `
    "Two monozygotic twins meet after years of being separated in quiet the unexpected fashion..."`,
    articlepagehref: `/AFondMemory`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 5,
    title: " May you have happiness, safety, and deepest well-being",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FselfCompassion.jpeg?alt=media&token=ec512bb6-ecbf-4a28-8ef4-f7dfe9747e7c
    `, 
    date: 'July 8th 2023',
    articleDesc: `
    We know that compassion for others begins with compassion for ourselves. So I thought we could focus on cultivating that during this week's meditation sessions..`,
    articlepagehref: `/MayYouHaveHappiness`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 6,
    title: "Affirmation",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FAffirmation.jpeg?alt=media&token=8f7928cc-bfe5-44fd-9360-a1be3f08b130
    `, 
    date: 'July 4th 2023',
    articleDesc: `
    I wanted to share with you the first time I said I love you. You will remember - I suspect in a much more accurate fashion than I. Yet, I remember lying by your side, our bodies intertwined, verbalising..`,
    articlepagehref: `/Affirmation`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 7,
    title: "Things Are CHANGING!",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FCompassionateLove.jpeg?alt=media&token=268f3a66-bef0-4b3c-9290-e493093065ac
    `, 
    date: 'July 1st 2023',
    articleDesc: `
    I am stuck on repeat. I realised late last night that essentially what I have been saying over the past week, while it is the truth and a reflection of different experiences`,
    articlepagehref: `/ThingsAreChanging`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 8,
    title: "Thoughts",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FleaningIn.jpeg?alt=media&token=01a9dadd-7387-481d-9018-a9aad6776d9e
    `, 
    date: 'June 30th 2023',
    articleDesc: `
    I just wanted to share a few statements from podcasts or interviews I enjoyed: poco a poco`,
    articlepagehref: `/Thoughts`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 9,
    title: "What is this and what could it be",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FleaningIn.jpeg?alt=media&token=01a9dadd-7387-481d-9018-a9aad6776d9e
    `, 
    date: 'June 30th 2023',
    articleDesc: `
    You were unsure of what I wanted. You felt the minute we got back together totally blocked out. Let me clarify what I truly want: I want to build a loving relationship with you...`,
    articlepagehref: `/WhatIsThis`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 10,
    title: "Leaning Into Fear",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2FleaningIn.jpeg?alt=media&token=01a9dadd-7387-481d-9018-a9aad6776d9e
    `, 
    date: 'June 29th 2023',
    articleDesc: `
    En serio, ¿cuál es la capital de Costa de Marfil? Tonight, I wanted to share a passage from the book I'm currently reading with you. I hope you're doing well and finding happiness, or at least wearing a smile, even if it's a half-hearted one. `,
    articlepagehref: `/LeaningIntoFear`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
  {
    uid: 11,
    title: "Taking It Personally",
    subtitle: "Relationship writings",
    ImageSrc: `
    https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1
    `, 
    date: 'June 25th 2023',
    articleDesc: `
    You got me. In response to reading that I started muttering sweet syllabus of rapture. Ranting and raving about how I bet she thinks I am emotionally immature, and that I do not realise that I am reactive...
        `,
    articlepagehref: `/TakingItPersonally`,
    image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
    imageAlt: `My thoughts`,
  }, 
    {
        uid: 12,
        title: "Shame",
        subtitle: "Relationship writings",
        ImageSrc: `
        https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1
        `, 
        date: 'June 23rd 2023',
        articleDesc: `
        Through the experience of shame and guilt, individuation can emerge. For some individuals, guilt and shame serve as catalysts that lead to a deeper understanding of the self and a more holistic perception of one's identity.
            `,
        articlepagehref: `/Shame`,
        image: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2Fshame.jpeg?alt=media&token=d1c226d7-cdea-4236-a567-eaca4512c6d1',
        imageAlt: `My thoughts`,
      }, 
    {
      uid: 13,
      title: "Conversational Receptiveness",
      subtitle: "Relationship writings",
      ImageSrc: 'https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2F_6b069575-e550-40d9-a3eb-02f4cff5f362.jpeg?alt=media&token=0918d4aa-6e35-4aba-8802-d4040e0d5dd7', 
      date: 'June 21st 2023',
      articleDesc: `
     Dr. Julia Minson an academic, social psychologist in the School of Public health at Harvard University returns home from a week full of mediation, for her new headlining research project uncovering the benefits of conversational receptiveness.
          `,
      articlepagehref: `/ConversationalReceptiveness`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `My thoughts`,
    }
]

export { moreArticles };