const moreArticles = [
    {
      uid: 1,
      title: "Improving your wellbeing for Adolescents ",
      subtitle: "Adolescent Wellbeing",
      articleDesc: `
      Adolescence is a period of transition characterized by rapid physical, emotional, and social changes. This can be a challenging time for many young people, with a range of mental...
          `,
      articlepagehref: `/ImproveYourWellbeingforAdolescents`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Improve Your Wellbeing for Adolescents Image`,
    },
    {
        uid: 2,
        title: "Physical Activity and Adolescent Wellbeing",
        subtitle: "Adolescent Wellbeing",
        articleDesc: `
        Adolescence is a crucial period in the development of physical, emotional, and social health. Research has consistently shown that regular physical...
            `,
        articlepagehref: `/PhysicalActivityAndAdolescentWellbeing`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Physical Activity and Adolescent Wellbeing Image`,
      },
      {
        uid: 3,
        title: "Positive Role Models for Adolescent Mental Health and Wellbeing",
        subtitle: "Adolescent Wellbeing",
        articleDesc: `
        Adolescence is a crucial period for mental health and wellbeing development. During this time, adolescents are exposed to various challenges such as academic pressures...
            `,
        articlepagehref: `/PositiveRoleModels`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Positive Role Models for Adolescent Mental Health and Wellbeing Image`,
      },
      {
        uid: 4,
        title: "Psychological Wellbeing During Adolescence",
        subtitle: "Adolescent Wellbeing",
        articleDesc: `
        Adolescence is a critical period of development characterized by significant physical, emotional, and social changes. It is during this time that individuals begin to form their own...  
            `,
        articlepagehref: `/PsychologicalWellbeingDuringAdolescence`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Psychological Wellbeing During Adolescence Image`,
      },
      {
        uid: 5,
        title: "Social media and wellbeing",
        subtitle: "Adolescent Wellbeing",
        articleDesc: `
        Social media has become an increasingly ubiquitous aspect of daily life, especially for adolescents. While social media platforms have brought many benefits...
            `,
        articlepagehref: `/SocialMediaAndWellbeing`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Social media and wellbeing Image`,
      }
]

export { moreArticles };