import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import mdIHaveBeenWrong from "../../Content/Nicole/IHaveBeenWrong.md";
import "../stylesheet.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function IHaveBeenWrong() {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(mdIHaveBeenWrong)
      .then((response) => response.text())
      .then((md) => {
        setContent(md);
      });
  }, []);

  const renderMarkdown = () => {
    const options = {
      wrapper: "article",
      overrides: {
        blockquote: {
          component: "blockquote",
          props: {
            className: "articleblockQuote",
          },
        },
        img: {
            component: CustomImageComponent,
          },
      },
    };
    return <Markdown options={options}>{content}</Markdown>;
  };

  const CustomImageComponent = ({ src, alt }) => {
    return <img src={src} alt={alt} style={{width: '100%', marginTop:'25px'}} />;
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>{renderMarkdown()}</Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default IHaveBeenWrong;
