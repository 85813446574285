import React, { useEffect, useState} from "react";
import { Container } from "react-bootstrap";
import Markdown from "markdown-to-jsx";

import mdAffirmation from "../../Content/Nicole/Affirmation.md";
import '../stylesheet.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


function Affirmation () {
        let [content, setContent] = useState({ md: "" });
      
        useEffect(() => {
          fetch(mdAffirmation)
            .then((response) => response.text())
            .then((md) => {
              setContent({ md });
            });
        }, []);

  return (
    /*include text inside the React.Fragement*/
    <React.Fragment>
      <Container>
      <Row>
        {/*<! Main page we need the course information>*/}
        <Col>      
            <Markdown>{content && content.md}</Markdown>
        </Col>
      </Row>
      </Container>
    </React.Fragment>
  );
};

export default Affirmation;