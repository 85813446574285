const moreArticles = [
    {
      uid: 1,
      title: "Breath-work for Mindfulness",
      subtitle: "Adult Mindfulness",
      articleDesc: `
      Breathing exercises are a simple and effective way to reduce stress and promote relaxation. Here is a breathing exercise that you can practice to help alleviate stress:
          `,
      articlepagehref: `/BreathWorkForMindfulness`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Breath-work for Mindfulness Image`,
    },
    {
        uid: 2,
        title: "But, what is adult mindfulness? ",
        subtitle: "Adult Mindfulness",
        articleDesc: `
        Mindfulness is a term that has gained popularity in recent years, as an effective tool for promoting mental wellbeing and reducing stress. It refers to the practice of focusing..
            `,
        articlepagehref: `/ButWhatisAdultMindfulness`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `But, what is adult mindfulness? Image`,
      },
      {
        uid: 3,
        title: "Mindfulness on a Day-to-Day",
        subtitle: "Adult Mindfulness",
        articleDesc: `
        Incorporating mindfulness into your daily routine can be a valuable tool for improving your mental health and wellbeing. Here are some steps you can take to create...
            `,
        articlepagehref: `/MindfulnessOnADayToDay`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Mindfulness on a Day-to-Day Image`,
      },
      {
        uid: 4,
        title: "Origins of Adult Anxiety",
        subtitle: "Adult Mindfulness",
        articleDesc: `
        Anxiety is a common experience for many adults, and it can manifest in different ways. Some people might experience physical symptoms such as a racing heart, sweating...  
            `,
        articlepagehref: `/OriginsOfAdultAnxiety`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Origins of Adult Anxiety Image`,
      },
      {
        uid: 5,
        title: "Yoga and mental health",
        subtitle: "Adult Mindfulness",
        articleDesc: `
        Yoga is a practice that combines physical postures, breath control, and meditation to promote physical and mental well-being. It has gained popularity...
            `,
        articlepagehref: `/YogaAndMentalHealth`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Parenting styles with Diana Baumrind Image`,
      }
]

export { moreArticles };