import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';



function SignUpNewsLetter() {
  return (
    <>
    <h4>
        Interested in hearing about the release of new articles and podcast episodes - subscribe to the <i>poco a poco</i> monthly newsletter today. 
    </h4>
        <Card>
            <Card.Body>
            <Row>
                <Col>
                Sign up for my personal wellbeing monthly newsletter + Podcast workshops and conversation where we, as a collective, can mutual learn to improve our wellbeing and mental health intelligence.
                </Col>
                <Col>
            <Col>
            <div class="container">
            <div class="cardEmailSub">
                <h1>Sign Up today!</h1>
                <p>Don't miss out our newly released content, get notified directly to your email!</p>
                <form action="#" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>
                <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Your email" required/>
                <button name="subscribe" id="mc-embedded-subscribe" class="button" type="submit">Subscribe me</button>
                </form>
            </div>
            </div>
            </Col>
                </Col>
            </Row>
            </Card.Body>
        </Card>
    </>
  );
}

export default SignUpNewsLetter;