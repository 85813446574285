import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import AllPodEpisodes from "./AllEpisodes/AllPodEpisodes";

function Podcast() {
  return (
    <Container>
      <Row>
        <Col>
        <h1>
            Podcasts
        </h1>
        <p>
        Wellbeing is how we're doing as individuals, communities and as a nation, and how sustainable that is for the future.
        </p>
        </Col>
        <Col>
            Insert Image Here
        </Col>
      </Row>
      <h2>
        Browse Articles and Newsletter
      </h2>
      <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>
                Poco a Poco
              </Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
            <small className="text-muted">
                <b>Next episode release date:</b> March 8<sup>th</sup>, 2023 
            </small>
            </Card.Footer>
          </Card>
          <center>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{color: '#ffffff',  backgroundColor: '#7991AB', borderBlockColor:'#7991AB', borderColor:'#7991AB;'}}>
                        Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Spotify</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Tidal</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Google Podcasts</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </center>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>
                with Nicole
              </Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
            <small className="text-muted">
                <b>Next episode release date:</b> March 15<sup>th</sup>, 2023 
            </small>
            </Card.Footer>
          </Card>
          <center>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic"
            style={{color: '#ffffff',  backgroundColor: '#7991AB', borderBlockColor:'#7991AB', borderColor:'#7991AB;'}}
            >
                    Dropdown Button
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Spotify</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Tidal</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Google Podcasts</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </center>
        </Col>
    </Row>
    <hr/>
    <h1>
      ALL PODCAST EPISODES
    </h1>
    <div className="ScrollableDiv">
      <AllPodEpisodes />
    </div>
    
    </Container>
  );
}

export default Podcast;