import React from "react";
import { Container } from "react-bootstrap";
import { 
    ParallaxLandingImage, 
    AboutMe, 
    MissionStatement, 
    CarouselFadeExample, 
    NewSnippet,  
    Podcasts,
    MonthlyNewsLetter
} from "../index";
import './mainPage.css';



function Main() {
    const width = "100%";
    return ( 
        <React.Fragment>
            <div className="SpacingDivs">
                <CarouselFadeExample />
            </div>
            <Container>
            <h1>
                Trending Topics
            </h1>
            <div className="SpacingDivs">
            <NewSnippet />
            </div>
            <hr />
            <div className="SpacingDivs">
            <AboutMe />
            </div>
            <hr />
            <div className="SpacingDivs">
            <MonthlyNewsLetter />
            </div>

            </Container>
        </React.Fragment>
        );
    }

    export default Main