const moreArticles = [
    {
      uid: 1,
      title: "Childhood Trauma And Addiction",
      subtitle: "Child Mental Health",
      articleDesc: `
      Childhood trauma and addiction are two interconnected issues that have long been a focus of Dr. Gabor Maté's work. Maté is a renowned physician, speaker,
          `,
      articlepagehref: `/ChildhoodTraumaAndAddiction`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Childhood TraumaAnd Addiciton Image`,
    },
    {
        uid: 2,
        title: "Jean Piaget on Child Psychology",
        subtitle: "Child Mental Health",
        articleDesc: `
        Jean Piaget was a Swiss developmental psychologist known for his groundbreaking work in child psychology. Piaget's research revolutionized our 
            `,
        articlepagehref: `/JeanPiagetonChildPsychology`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Jean Piaget on Child Psychology Image`,
      },
      {
        uid: 3,
        title: "Mary Ainsworth on Developmental Psychology",
        subtitle: "Child Mental Health",
        articleDesc: `
        Mary Ainsworth was a renowned developmental psychologist whose groundbreaking research on attachment has had a profound impact on our understanding of child development and relationships.
            `,
        articlepagehref: `/MaryAinsworthDevelopmentalPsychology`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Mary Ainsworth Developmental Psychology Image`,
      },
      {
        uid: 4,
        title: "Professor Michael Rutter - Father of Child Psychology",
        subtitle: "Child Mental Health",
        articleDesc: `
        Michael Rutter is a renowned British child psychiatrist and researcher who has made significant contributions to our understanding of child development, mental health...  
            `,
        articlepagehref: `/MichaelRutterChildPsychology`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Professor Michael Rutter Image`,
      },
      {
        uid: 5,
        title: "Parenting styles with Diana Baumrind",
        subtitle: "Child Mental Health",
        articleDesc: `
        Diana Baumrind was a prominent clinical and developmental psychologist who made significant contributions to our understanding of parenting styles, child development...
            `,
        articlepagehref: `/ParentingStylesWithDianaBaumrind`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Parenting styles with Diana Baumrind Image`,
      }
]

export { moreArticles };