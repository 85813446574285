import React from "react";
import './aboutMe.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from "react-bootstrap";
function aboutMe() {
    const width = "100%";
    return ( 
        <React.Fragment>
        <Container>
        <Row>
        <Col sm={6}>
        <img src='https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/NicolePhoto.webp?alt=media&token=1160d14c-e213-4eab-bd81-ae8c8a549a08' className="AboutNicoleImage" />
        </Col>
        <Col sm={6}>
        <h1>
                About Nicole Curtis
            </h1>
            <p>
                Nicole has a deep knowledge of Neuroscience and Psychology – especially regarding Adolescent and Child Health. This has been the primary focus of her educational journey; undertaking two part-time postgraduate degrees - while juggling her professional responsibilities as a Mental Health Worker at Secondary School establishments throughout London (United College Group, and Barnet and Southgate College), and leading the ‘Mighty Minds: Wellbeing programme’ at Off the Curriculum.
            </p>
        </Col>
        </Row>
        </Container>
        </React.Fragment>
        );
    }
    export default aboutMe;