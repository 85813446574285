const moreArticles = [
    {
      uid: 1,
      title: "Childhood Trauma And Addiction",
      subtitle: "Child Mental Health",
      articleDesc: `
      Childhood trauma and addiction are two interconnected issues that have long been a focus of Dr. Gabor Maté's work. Maté is a renowned physician, speaker,
          `,
      articlepagehref: `/ChildhoodTraumaAndAddiction`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Childhood TraumaAnd Addiciton Image`,
    },
    {
        uid: 2,
        title: "Jean Piaget on Child Psychology",
        subtitle: "Child Mental Health",
        articleDesc: `
        Jean Piaget was a Swiss developmental psychologist known for his groundbreaking work in child psychology. Piaget's research revolutionized our 
            `,
        articlepagehref: `/JeanPiagetonChildPsychology`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Jean Piaget on Child Psychology Image`,
      },
      {
        uid: 3,
        title: "Mary Ainsworth on Developmental Psychology",
        subtitle: "Child Mental Health",
        articleDesc: `
        Mary Ainsworth was a renowned developmental psychologist whose groundbreaking research on attachment has had a profound impact on our understanding of child development and relationships.
            `,
        articlepagehref: `/MaryAinsworthDevelopmentalPsychology`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Mary Ainsworth Developmental Psychology Image`,
      },
      {
        uid: 4,
        title: "Professor Michael Rutter - Father of Child Psychology",
        subtitle: "Child Mental Health",
        articleDesc: `
        Michael Rutter is a renowned British child psychiatrist and researcher who has made significant contributions to our understanding of child development, mental health...  
            `,
        articlepagehref: `/MichaelRutterChildPsychology`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Professor Michael Rutter Image`,
      },
      {
        uid: 5,
        title: "Parenting styles with Diana Baumrind",
        subtitle: "Child Mental Health",
        articleDesc: `
        Diana Baumrind was a prominent clinical and developmental psychologist who made significant contributions to our understanding of parenting styles, child development...
            `,
        articlepagehref: `/ParentingStylesWithDianaBaumrind`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Parenting styles with Diana Baumrind Image`,
      }, 
      {
        uid: 6,
        title: "Improving your wellbeing for Adolescents ",
        subtitle: "Adolescent Wellbeing",
        articleDesc: `
        Adolescence is a period of transition characterized by rapid physical, emotional, and social changes. This can be a challenging time for many young people, with a range of mental...
            `,
        articlepagehref: `/ImproveYourWellbeingforAdolescents`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Improve Your Wellbeing for Adolescents Image`,
      },
      {
          uid: 7,
          title: "Physical Activity and Adolescent Wellbeing",
          subtitle: "Adolescent Wellbeing",
          articleDesc: `
          Adolescence is a crucial period in the development of physical, emotional, and social health. Research has consistently shown that regular physical...
              `,
          articlepagehref: `/PhysicalActivityAndAdolescentWellbeing`,
          image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
          imageAlt: `Physical Activity and Adolescent Wellbeing Image`,
        },
        {
          uid: 8,
          title: "Positive Role Models for Adolescent Mental Health and Wellbeing",
          subtitle: "Adolescent Wellbeing",
          articleDesc: `
          Adolescence is a crucial period for mental health and wellbeing development. During this time, adolescents are exposed to various challenges such as academic pressures...
              `,
          articlepagehref: `/PositiveRoleModels`,
          image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
          imageAlt: `Positive Role Models for Adolescent Mental Health and Wellbeing Image`,
        },
        {
          uid: 9,
          title: "Psychological Wellbeing During Adolescence",
          subtitle: "Adolescent Wellbeing",
          articleDesc: `
          Adolescence is a critical period of development characterized by significant physical, emotional, and social changes. It is during this time that individuals begin to form their own...  
              `,
          articlepagehref: `/PsychologicalWellbeingDuringAdolescence`,
          image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
          imageAlt: `Psychological Wellbeing During Adolescence Image`,
        },
        {
          uid: 10,
          title: "Social media and wellbeing",
          subtitle: "Adolescent Wellbeing",
          articleDesc: `
          Social media has become an increasingly ubiquitous aspect of daily life, especially for adolescents. While social media platforms have brought many benefits...
              `,
          articlepagehref: `/SocialMediaAndWellbeing`,
          image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
          imageAlt: `Social media and wellbeing Image`,
        }, 
        {
          uid: 11,
          title: "Breath-work for Mindfulness",
          subtitle: "Adult Mindfulness",
          articleDesc: `
          Breathing exercises are a simple and effective way to reduce stress and promote relaxation. Here is a breathing exercise that you can practice to help alleviate stress:
              `,
          articlepagehref: `/BreathWorkForMindfulness`,
          image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
          imageAlt: `Breath-work for Mindfulness Image`,
        },
        {
            uid: 12,
            title: "But, what is adult mindfulness? ",
            subtitle: "Adult Mindfulness",
            articleDesc: `
            Mindfulness is a term that has gained popularity in recent years, as an effective tool for promoting mental wellbeing and reducing stress. It refers to the practice of focusing..
                `,
            articlepagehref: `/ButWhatisAdultMindfulness`,
            image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
            imageAlt: `But, what is adult mindfulness? Image`,
          },
          {
            uid: 13,
            title: "Mindfulness on a Day-to-Day",
            subtitle: "Adult Mindfulness",
            articleDesc: `
            Incorporating mindfulness into your daily routine can be a valuable tool for improving your mental health and wellbeing. Here are some steps you can take to create...
                `,
            articlepagehref: `/MindfulnessOnADayToDay`,
            image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
            imageAlt: `Mindfulness on a Day-to-Day Image`,
          },
          {
            uid: 14,
            title: "Origins of Adult Anxiety",
            subtitle: "Adult Mindfulness",
            articleDesc: `
            Anxiety is a common experience for many adults, and it can manifest in different ways. Some people might experience physical symptoms such as a racing heart, sweating...  
                `,
            articlepagehref: `/OriginsOfAdultAnxiety`,
            image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
            imageAlt: `Origins of Adult Anxiety Image`,
          },
          {
            uid: 15,
            title: "Yoga and mental health",
            subtitle: "Adult Mindfulness",
            articleDesc: `
            Yoga is a practice that combines physical postures, breath control, and meditation to promote physical and mental well-being. It has gained popularity...
                `,
            articlepagehref: `/YogaAndMentalHealth`,
            image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
            imageAlt: `Parenting styles with Diana Baumrind Image`,
          }, 
          {
            uid: 16,
            title: "How to foster vulnerability in relationships",
            subtitle: "Relationship Writings",
            articleDesc: `
            Vulnerability is the cornerstone of intimacy and trust in a relationship. It requires opening up and being willing to show our true selves, including our fears, insecurities, and weaknesses...
                `,
            articlepagehref: `/HowToFosterVulnerabilityInRelationships`,
            image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
            imageAlt: `How to foster vulnerability in relationships Image`,
          },
          {
              uid: 17,
              title: "How to have difficult discussions with your significant other",
              subtitle: "Relationship Writings",
              articleDesc: `
              Having difficult conversations with your significant other is an essential part of maintaining a healthy and thriving relationship. While these conversations...
                  `,
              articlepagehref: `/HowToHaveDifficultDiscussions`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `How to have difficult discussions with your significant other Image`,
            },
            {
              uid: 18,
              title: "Importance of Maintaining Individuality in relationships",
              subtitle: "Relationship Writings",
              articleDesc: `
              Maintaining individuality in a relationship is crucial for personal growth, a healthy partnership, and a fulfilling life. While being...
                  `,
              articlepagehref: `/MaintainingIndividualityInRelationships`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `Importance of Maintaining Individuality in relationships Image`,
            },
            {
              uid: 19,
              title: "Questions to ask a new partner",
              subtitle: "Relationship Writings",
              articleDesc: `
              When starting a new relationship, it's important to get to know your partner on a deeper level. While there are some questions...  
                  `,
              articlepagehref: `/QuestionsToAskANewPartner`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `Questions to ask a new partner Image`,
            },
            {
              uid: 20,
              title: "What is Love",
              subtitle: "Relationship Writings",
              articleDesc: `
              Love is a powerful and multifaceted emotion that forms the foundation of deep and meaningful relationships. When it...
                  `,
              articlepagehref: `/WhatIsLove`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `What is Love Image`,
            }, 
            {
              uid: 21,
            title: "Air pollution and depression",
            subtitle: "Environment And Mental Health",
            articleDesc: `
            Air pollution, a prevalent environmental issue in many parts of the world, has been linked to a wide range of adverse health effects. While its...
                `,
            articlepagehref: `/AirPollutionAndDepression`,
            image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
            imageAlt: `Air pollution and depression triggers Image`,
          },
          {
              uid: 22,
              title: "Air pollution and mental health",
              subtitle: "Environment And Mental Health",
              articleDesc: `
              Air pollution is a significant environmental issue that has been linked to a range of physical health problems, including respiratory and cardiovascular diseases...
                  `,
              articlepagehref: `/AirPollutionAndMentalHealth`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `Air pollution and mental health Image`,
            },
            {
              uid: 23,
              title: "Chemical pollution and mental health",
              subtitle: "Environment And Mental Health",
              articleDesc: `
              Diet plays a vital role in our physical health, but emerging research suggests that it may also have a significant impact on our mental well-being... 
                  `,
              articlepagehref: `/ChemicalPollutionAndMentalHealth`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `Chemical pollution and mental health Image`,
            },
            {
              uid: 24,
              title: "Housing And Mental Health",
              subtitle: "Environment And Mental Health",
              articleDesc: `
              The importance of having access to adequate housing cannot be overstated. Housing provides us with shelter, safety, and security, and is essential...  
                  `,
              articlepagehref: `/HousingAndMentalHealth`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `Housing And Mental Health Image`,
            }, 
            {
              uid: 25,
              title: "Water pollution and mental health",
              subtitle: "Environment And Mental Health",
              articleDesc: `
              Water is one of the most essential resources for human survival. However, with the increase in industrialization and human activity, water pollution...  
                  `,
              articlepagehref: `/WaterPollutionAndMentalHealth`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `Water pollution and mental health Image`,
            }, 
            {
              uid: 26,
              title: "Identifying your stress triggers",
              subtitle: "Stress And Self-Esteem",
              articleDesc: `
              Stress is an inevitable part of life, and it can be caused by a wide range of triggers, both internal and external. Identifying the factors that...
                  `,
              articlepagehref: `/IdentifyingStressTriggers`,
              image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
              imageAlt: `Identifying your stress triggers Image`,
            },
            {
                uid: 27,
                title: "The Neuroscience of Stress",
                subtitle: "Stress And Self-Esteem",
                articleDesc: `
                Stress is a natural response to perceived threats, and it can be caused by a wide range of stimuli, including psychological, environmental, and physiological...
                    `,
                articlepagehref: `/NeuroscientificExaminationOfStress`,
                image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
                imageAlt: `The Neuroscience of Stress Image`,
              },
              {
                uid: 28,
                title: "Stress and it's influence on self-esteem",
                subtitle: "Stress And Self-Esteem",
                articleDesc: `
                Stress is a natural response to perceived threats, and it can have a significant impact on an individual's mental health and well-being. One way in which stress... 
                    `,
                articlepagehref: `/StressAndSelfEsteem`,
                image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
                imageAlt: `Stress and it's influence on self-esteem Image`,
              },
              {
                uid: 29,
                title: "Stress Management",
                subtitle: "Stress And Self-Esteem",
                articleDesc: `
                Stress is a common part of life, and while some stress can be motivating and even beneficial, excessive or chronic stress can have negative impacts h...  
                    `,
                articlepagehref: `/StressManagement`,
                image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
                imageAlt: `Stress And Self-Esteem Image`,
              }, 
              {
                uid: 30,
                title: "What is Self-Esteem",
                subtitle: "Stress And Self-Esteem",
                articleDesc: `
                Self-esteem is a psychological construct that refers to an individual's overall evaluation of their own worth or value. It is an important...  
                    `,
                articlepagehref: `/WhatIsSelfEsteem`,
                image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
                imageAlt: `What is Self-Esteem Image`,
              }
]

export { moreArticles };