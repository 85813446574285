import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import mdWhatIsThis from "../../Content/Nicole/WhatIsThis.md";
import "../stylesheet.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function WhatIsThis() {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(mdWhatIsThis)
      .then((response) => response.text())
      .then((md) => {
        setContent(md);
      });
  }, []);

  const renderMarkdown = () => {
    const options = {
      wrapper: "article",
      overrides: {
        blockquote: {
          component: "blockquote",
          props: {
            className: "articleblockQuote",
          },
        },
      },
    };
    return <Markdown options={options}>{content}</Markdown>;
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>{renderMarkdown()}</Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default WhatIsThis;
