const moreArticles = [
    {
      uid: 1,
      title: "Identifying your stress triggers",
      subtitle: "Stress And Self-Esteem",
      articleDesc: `
      Stress is an inevitable part of life, and it can be caused by a wide range of triggers, both internal and external. Identifying the factors that...
          `,
      articlepagehref: `/IdentifyingStressTriggers`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `Identifying your stress triggers Image`,
    },
    {
        uid: 2,
        title: "The Neuroscience of Stress",
        subtitle: "Stress And Self-Esteem",
        articleDesc: `
        Stress is a natural response to perceived threats, and it can be caused by a wide range of stimuli, including psychological, environmental, and physiological...
            `,
        articlepagehref: `/NeuroscientificExaminationOfStress`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `The Neuroscience of Stress Image`,
      },
      {
        uid: 3,
        title: "Stress and it's influence on self-esteem",
        subtitle: "Stress And Self-Esteem",
        articleDesc: `
        Stress is a natural response to perceived threats, and it can have a significant impact on an individual's mental health and well-being. One way in which stress... 
            `,
        articlepagehref: `/StressAndSelfEsteem`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Stress and it's influence on self-esteem Image`,
      },
      {
        uid: 4,
        title: "Stress Management",
        subtitle: "Stress And Self-Esteem",
        articleDesc: `
        Stress is a common part of life, and while some stress can be motivating and even beneficial, excessive or chronic stress can have negative impacts h...  
            `,
        articlepagehref: `/StressManagement`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Stress And Self-Esteem Image`,
      }, 
      {
        uid: 5,
        title: "What is Self-Esteem",
        subtitle: "Stress And Self-Esteem",
        articleDesc: `
        Self-esteem is a psychological construct that refers to an individual's overall evaluation of their own worth or value. It is an important...  
            `,
        articlepagehref: `/WhatIsSelfEsteem`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `What is Self-Esteem Image`,
      }
]

export { moreArticles };