const moreArticles = [
    {
      uid: 1,
      title: "How to foster vulnerability in relationships",
      subtitle: "Relationship Writings",
      articleDesc: `
      Vulnerability is the cornerstone of intimacy and trust in a relationship. It requires opening up and being willing to show our true selves, including our fears, insecurities, and weaknesses...
          `,
      articlepagehref: `/HowToFosterVulnerabilityInRelationships`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      imageAlt: `How to foster vulnerability in relationships Image`,
    },
    {
        uid: 2,
        title: "How to have difficult discussions with your significant other",
        subtitle: "Relationship Writings",
        articleDesc: `
        Having difficult conversations with your significant other is an essential part of maintaining a healthy and thriving relationship. While these conversations...
            `,
        articlepagehref: `/HowToHaveDifficultDiscussions`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `How to have difficult discussions with your significant other Image`,
      },
      {
        uid: 3,
        title: "Importance of Maintaining Individuality in relationships",
        subtitle: "Relationship Writings",
        articleDesc: `
        Maintaining individuality in a relationship is crucial for personal growth, a healthy partnership, and a fulfilling life. While being...
            `,
        articlepagehref: `/MaintainingIndividualityInRelationships`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Importance of Maintaining Individuality in relationships Image`,
      },
      {
        uid: 4,
        title: "Questions to ask a new partner",
        subtitle: "Relationship Writings",
        articleDesc: `
        When starting a new relationship, it's important to get to know your partner on a deeper level. While there are some questions...  
            `,
        articlepagehref: `/QuestionsToAskANewPartner`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `Questions to ask a new partner Image`,
      },
      {
        uid: 5,
        title: "What is Love",
        subtitle: "Relationship Writings",
        articleDesc: `
        Love is a powerful and multifaceted emotion that forms the foundation of deep and meaningful relationships. When it...
            `,
        articlepagehref: `/WhatIsLove`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
        imageAlt: `What is Love Image`,
      }
]

export { moreArticles };