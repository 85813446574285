import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Button } from "react-bootstrap";
import { moreArticles } from "./MainContent/Index";

function Nicole() {
  return (
    <Container>
      <Row>
        <Col>
        <h1>
            Hello - Joseph here!
        </h1>
        <p>
        Welcome to my series on being a smart idiot. The thing you said that was positive about me was that I was disciplined, oh and a workaholic. So I am trying to not live so much in my head, and dedicate time to express how I feel about you, about our life, and to tell you how I feel. 
        </p>
        </Col>
        <Col>
        <img src='https://firebasestorage.googleapis.com/v0/b/pastpresenttense-5cc9a.appspot.com/o/pocoapoco%2F_a0db53f8-bd1a-4489-ae07-44425254caef.jpeg?alt=media&token=88018818-5b56-413d-87b8-a4348713f83f' width={300} style={{padding: '20px', borderRadius: 35}} />
        </Col>
      </Row>
      <h2>
        Browse Articles and Newsletter
      </h2>
    <Row>
    <Col>
      <div className="ScrollableDiv">
      {moreArticles.map((items) => (
        <div className='articleSpacing'>
        <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src={items.ImageSrc} style={{ width:'100%', height: 200, borderRadius: 5}} />
            <Card.Body>
                <Card.Title> {items.title}</Card.Title>
                <Card.Text>
                {items.articleDesc}
                </Card.Text>
            </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item>{items.subtitle}</ListGroup.Item>
                    <ListGroup.Item>Author: Nicole Curtis | {items.date}</ListGroup.Item>
                </ListGroup>
                <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href={items.articlepagehref}className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
            </div>
                    ))}    
        </div>  
    </Col>
    </Row>
    </Container>
  );
}

export default Nicole;