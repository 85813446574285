import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { moreArticles } from "./MainContent/Index";

function Childmentalhealth() {
  return (
<Container>
      <Row>
        <Col>
        <h1>
            Child Mental Health
        </h1>
        <p>
        Mental health is an individual's cognitive, behavioural and emotional wellbeing (Mind, 2020). It's something we all have - including every child and young person. We use the term "mental health issues" to refer to mental health problems, conditions and mental illnesses.
        </p>
        </Col>
        <Col>Insert Image Here</Col>
      </Row>
      <h2>
        Browse Articles and Newsletter
      </h2>
      <div className="ScrollableDiv">
      <Row xs={1} md={3} className="g-4">
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
    </Row>
    </div>
    <Row>
    <Col>
      <div className="ScrollableDiv">
      {moreArticles.map((items) => (
        <div className='articleSpacing'>
        <Card style={{ width: '100%' }}>
            <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
            <Card.Body>
                <Card.Title> {items.title}</Card.Title>
                <Card.Text>
                {items.articleDesc}
                </Card.Text>
            </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item>{items.subtitle}</ListGroup.Item>
                    <ListGroup.Item>Author: Nicole Curtis</ListGroup.Item>
                </ListGroup>
                <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href={items.articlepagehref}className='articleButton'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card>
            </div>
                    ))}    
        </div>  
    </Col>
    </Row>
    </Container>
  );
}

export default Childmentalhealth;