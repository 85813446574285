import logo from './logo.svg';
import { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header, Footer, NavBar } from './components/common';
import { Main } from './components/landingPage';
import Articles from './components/articles/mainPage/mainPage.js'; 
import GA4React from "ga-4-react";
///Importing the focus tabbed pages
import {
  Adolescentwellbeing, 
  Childmentalhealth, 
  Environmentandmentalhealth, 
  Adultmindfulness, 
  Relationshipwritings, 
  Stressandselfesteem,
  Nicole
} from './components/focusOn/index';

///Import the About us pages
import {
  NicoleCurtis, 
  Pocoapoca
} from './components/about/index';
/// Import podcast page 
import Podcast from './components/podcast/Mainpage';

///Articles
import { 
  ImproveYourWellbeingforAdolescents, 
  PhysicalActivityAndAdolescentWellbeing, 
  PositiveRoleModels, 
  PsychologicalWellbeingDuringAdolescence, 
  SocialMediaAndWellbeing, 
  BreathWorkForMindfulness, 
  ButWhatisAdultMindfulness, 
  OriginsOfAdultAnxiety, 
  YogaAndMentalHealth, 
  ChildhoodTraumaAndAddiction, 
  JeanPiagetonChildPsychology, 
  MaryAinsworthDevelopmentalPsychology, 
  MichaelRutterChildPsychology, 
  ParentingStylesWithDianaBaumrind, 
  AirPollutionAndDepression, 
  AirPollutionAndMentalHealth, 
  ChemicalPollutionAndMentalHealth, 
  HousingAndMentalHealth, 
  WaterPollutionAndMentalHealth, 
  HowToFosterVulnerabilityInRelationships, 
  HowToHaveDifficultDiscussions, 
  MaintainingIndividualityInRelationships, 
  QuestionsToAskANewPartner, 
  WhatIsLove, 
  IdentifyingStressTriggers, 
  NeuroscientificExaminationOfStress, 
  StressAndSelfEsteem, 
  StressManagement, 
  WhatIsSelfEsteem, 
  EmpathyWithHelenRiess, 
  PeaceFromWithin, 
  SpiritualJourney, 
  TheEnvironmentAndMentalHealth, 
  ConversationalReceptiveness,
  Shame, 
  TakingItPersonally, 
  LeaningIntoFear, 
  WhatIsThis, 
  Thoughts, 
  ThisAreChanging, 
  Affirmation, 
  MayYouHaveHappiness, 
  AFondMemory,
  WeHaveHadOurFirstPersonLogin,
  IHaveBeenWrong, 
  Reassurance
 } from './Pages/Articles/TemplatePage/Index';

import ArticleTemplatePage from './Pages/Articles/TemplatePage/ArticleTemplatePage';
import ArticleTwo from './Pages/Articles/TemplatePage/ArticleTwo';
import ArticleName from './Pages/Articles/TemplatePage/ArticleThree';

function App() {
  const ga4react = new GA4React("G-T4DMZDR6FK", {debug_mode: true});
  useEffect(() => {
    ga4react.initialize().then(
      (ga4) => {
        ga4.pageview("path");
        ga4.gtag("pageview", "path"); // or your custom gtag event
      },
      (err) => {
      }
    );
  });
  
  return (
    <Router>
     <Header/>
     <NavBar />
        <Routes>
          {/*Main landing page*/}
          <Route path="/" element={<Main />} />
          {/*Routing the main blog page*/}
          <Route path='/Articles' element={<Articles />} />
          {/*Routing the Focus tabbed pages into the router system*/}
          <Route path='/Adolescentwellbeing' element={<Adolescentwellbeing />} />
          <Route path='/Childmentalhealth' element={<Childmentalhealth />} />
          <Route path='/Relationshipwritings' element={<Relationshipwritings />} />
          <Route path='/Adolescentmindfulness' element={<Adultmindfulness />} />
          <Route path='/Environmentandmentalhealth' element={<Environmentandmentalhealth />} />
          <Route path='/Stressandselfesteem' element={<Stressandselfesteem />} />
          <Route path='/Nicole' element={<Nicole />} />
          {/*Routing the About Us pages*/}
          <Route path='/Aboutnicole' element={<NicoleCurtis />} />
          <Route path='/Pocoapoco' element={<Pocoapoca />} />
          {/*Routing the Podcast page*/}
          <Route path='/Podcasts' element={<Podcast />} />
          {/*Routing the News page*/}
          <Route path='/ImproveYourWellbeingforAdolescents' element={<ImproveYourWellbeingforAdolescents />} />
          <Route path='/PhysicalActivityAndAdolescentWellbeing' element={<PhysicalActivityAndAdolescentWellbeing />} />
          <Route path='/PositiveRoleModels' element={<PositiveRoleModels />} />
          <Route path='/PsychologicalWellbeingDuringAdolescence' element={<PsychologicalWellbeingDuringAdolescence />} />
          <Route path='/SocialMediaAndWellbeing' element={<SocialMediaAndWellbeing />} />
          <Route path='/BreathWorkForMindfulness' element={<BreathWorkForMindfulness />} />
          <Route path='/ButWhatisAdultMindfulness' element={<ButWhatisAdultMindfulness />} />
          <Route path='/OriginsOfAdultAnxiety' element={<OriginsOfAdultAnxiety />} />
          <Route path='/YogaAndMentalHealth' element={<YogaAndMentalHealth />} />
          <Route path='/ChildhoodTraumaAndAddiction' element={<ChildhoodTraumaAndAddiction />} />
          <Route path='/JeanPiagetonChildPsychology' element={<JeanPiagetonChildPsychology />} />
          <Route path='/MaryAinsworthDevelopmentalPsychology' element={<MaryAinsworthDevelopmentalPsychology />} />
          <Route path='/MichaelRutterChildPsychology' element={<MichaelRutterChildPsychology />} />
          <Route path='/ParentingStylesWithDianaBaumrind' element={<ParentingStylesWithDianaBaumrind />} />
          <Route path='/AirPollutionAndDepression' element={<AirPollutionAndDepression />} />
          <Route path='/AirPollutionAndMentalHealth' element={<AirPollutionAndMentalHealth />} />
          <Route path='/ChemicalPollutionAndMentalHealth' element={<ChemicalPollutionAndMentalHealth />} />
          <Route path='/HousingAndMentalHealth' element={<HousingAndMentalHealth />} />
          <Route path='/WaterPollutionAndMentalHealth' element={<WaterPollutionAndMentalHealth />} />
          <Route path='/HowToFosterVulnerabilityInRelationships' element={<HowToFosterVulnerabilityInRelationships />} />
          <Route path='/HowToHaveDifficultDiscussions' element={<HowToHaveDifficultDiscussions />} />
          <Route path='/MaintainingIndividualityInRelationships' element={<MaintainingIndividualityInRelationships />} />
          <Route path='/QuestionsToAskANewPartner' element={<QuestionsToAskANewPartner />} />
          <Route path='/WhatIsLove' element={<WhatIsLove />} />
          <Route path='/IdentifyingStressTriggers' element={<IdentifyingStressTriggers />} />
          <Route path='/NeuroscientificExaminationOfStress' element={<NeuroscientificExaminationOfStress />} />
          <Route path='/StressAndSelfEsteem' element={<StressAndSelfEsteem />} />
          <Route path='/StressManagement' element={<StressManagement />} />
          <Route path='/WhatIsSelfEsteem' element={<WhatIsSelfEsteem />} />
          <Route path='/EmpathyWithHelenRiess' element={<EmpathyWithHelenRiess />} />
          <Route path='/PeaceFromWithin' element={<PeaceFromWithin />} />
          <Route path='/SpiritualJourney' element={<SpiritualJourney />} />
          <Route path='/TheEnvironmentAndMentalHealth' element={<TheEnvironmentAndMentalHealth />} />
          <Route path='/ConversationalReceptiveness' element={<ConversationalReceptiveness />} />
          <Route path='/Shame' element={<Shame />} />
          <Route path='/TakingItPersonally' element={<TakingItPersonally />} />
          <Route path='/LeaningIntoFear' element={<LeaningIntoFear />} />
          <Route path='/WhatIsThis' element={<WhatIsThis />} />
          <Route path='/Thoughts' element={<Thoughts />} />
          <Route path='/ThingsAreChanging' element={<ThisAreChanging />} />
          <Route path='/Affirmation' element={<Affirmation />} />
          <Route path='/MayYouHaveHappiness' element={<MayYouHaveHappiness />} />
          <Route path='/AFondMemory' element={<AFondMemory />} />
          <Route path='/WeHaveHadOurFirstPersonLogin' element={<WeHaveHadOurFirstPersonLogin />} />
          <Route path='/IWasWrong' element={<IHaveBeenWrong />} />
          <Route path='/Reassurance' element={<Reassurance />} />
          <Route path="*" element={<Main />} />
        </Routes>
      <Footer/>

    </Router>
  );
}

export default App;