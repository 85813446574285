import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './NewSnippet.css'
import { Button } from 'react-bootstrap';
function NewSnippet() {
  return (
    <Row xs={1} md={2} className="g-4">
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>How to start your spiritual journey</Card.Title>
              <Card.Text>
              A spiritual journey is a personal exploration of one's beliefs and values, and it can lead to a deeper understanding of the self and the world around us...
              </Card.Text>
              <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href='/SpiritualJourney'
                            className='/SpiritualJourney'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    March 1<sup>st</sup> 2023 | 5 mins read | Nicole Curtis, MSc
                </small>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Peace comes from within</Card.Title>
              <Card.Text>
              Peace is a state of mind and a state of being that can be achieved from within. While external factors such as conflict, stress, and difficult life circumstances...
              </Card.Text>
              <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href='/PeaceFromWithin'
                            className='/PeaceFromWithin'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    March 3<sup>rd</sup> 2023 | 5 mins read | Nicole Curtis, MSc
                </small>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>The environment and mental health</Card.Title>
              <Card.Text>
              The environment in which we live can have a significant impact on our mental health. From the air we breathe to the places we spend our time...
              </Card.Text>
              <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href='/TheEnvironmentAndMentalHealth'
                            className='/TheEnvironmentAndMentalHealth'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    March 5<sup>th</sup> 2023 | 5 mins read | Nicole Curtis, MSc
                </small>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>EMPATHY with Dr. Helen Riess</Card.Title>
              <Card.Text>
              Empathy is the ability to understand and share the feelings of another person. It is a vital component of healthy relationships, effective communication...
              </Card.Text>
              <center>
                        <Card.Body>
                            <Button variant="primary" style={{color: '#ffffff',  backgroundColor: '#F17C73', borderBlockColor:'#F17C73', borderColor:'#F17C73;'}}>
                            <Card.Link href='/EmpathyWithHelenRiess'
                            className='/EmpathyWithHelenRiess'>
                                Read more here...
                                </Card.Link>
                            </Button>{' '}
                        </Card.Body>
                    </center>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    March 10<sup>th</sup> 2023 | 5 mins read | Nicole Curtis, MSc
                </small>
            </Card.Footer>
          </Card>
        </Col>
    </Row>
  );
}

export default NewSnippet;