import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";

function Footer() {
    return ( 
        <React.Fragment>
        <footer class="site-footer">
        <div class="container">
            <div class="row">
            <div class="col-sm-12 col-md-6">
                <h6>About</h6>
                <p class="text-justify">
                    pocoapoco.io <i>SMALL STEPS IN THE RIGHT DIRECTION CAN CHANGE A MIND FOREVER </i> is an initiative  to promote wellness and mental health for all members of society, exploring practices of self-awareness, to spiritual healing and resilience, through to how our environment - whether that be your home and immediate family or air pollution contribution to poor mental health and cognitive decline. I hope the series of articles, podcasts, and video-educational content motivates you to concentrate on you and your wellbeing, so you may thrive in moments of uncertainty. 
                </p>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Categories</h6>
                <ul class="footer-links">
                <li>
                    <Link to="/Childmentalhealth">
                        Child Mental Health
                    </Link>
                </li>
                <li>
                    <Link to="/Adolescentwellbeing">
                        Adolescent Wellbeing
                    </Link>
                </li>
                <li>
                    <Link to="/Adolescentmindfulness">
                        Adult Mindfulness
                    </Link>
                </li>
                <li>
                    <Link to="/Relationshipwritings">
                        Relationship Writings
                    </Link>
                </li>
                <li>
                    <Link to="/Stressandselfesteem">
                        Stress and self-esteem
                    </Link>
                </li>
                <li>
                    <Link to="/Environmentandmentalhealth">
                        The environment and mental health
                    </Link>
                </li>
                </ul>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Quick Links</h6>
                <ul class="footer-links">
                <li><Link to="/Pocoapoco">About Poco a Poco</Link></li>
                <li><Link to="/Aboutnicole">About Nicole Curtis</Link></li>
                <li><a href="http://scanfcode.com/contribute-at-scanfcode/">Contribute</a></li>
                <li><a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a></li>
                <li><a href="http://scanfcode.com/sitemap/">Terms and Conditions</a></li>
                </ul>
            </div>
            </div>
            <hr />
        </div>
        <div class="container">
            <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
                <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by 
                </p>
                <a href="#">Levermore and Code</a>.
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
                <ul class="social-icons">
                <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a class="dribbble" href="#"><i class="fa fa-dribbble"></i></a></li>
                <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>   
                </ul>
            </div>
            </div>
        </div>
    </footer>
        </React.Fragment>
        );
    }
    export default Footer